*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  overflow: hidden;

  @media only screen and (max-width: 48em) {
    font-size: 56.25%;
  }

  @media only screen and (max-width: 26.563em) {
    font-size: 50%;
  }
}

body {
  font-weight: 400;
  line-height: 1.5;
}

#app {
  position: relative;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('../assets/fonts/cerapro/CeraPro-Regular.eot');
  src: url('../assets/fonts/cerapro/CeraPro-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/cerapro/CeraPro-Regular.woff') format('woff'),
    url('../assets/fonts/cerapro/CeraPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('../assets/fonts/cerapro/CeraPro-Italic.eot');
  src: url('../assets/fonts/cerapro/CeraPro-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/cerapro/CeraPro-Italic.woff') format('woff'),
    url('../assets/fonts/cerapro/CeraPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('../assets/fonts/cerapro/CeraPro-Bold.eot');
  src: url('../assets/fonts/cerapro/CeraPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/cerapro/CeraPro-Bold.woff') format('woff'),
    url('../assets/fonts/cerapro/CeraPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.virtualized-list {
  scroll-behavior: smooth;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
