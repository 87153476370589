*, :before, :after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  overflow: hidden;
}

@media only screen and (width <= 48em) {
  html {
    font-size: 56.25%;
  }
}

@media only screen and (width <= 26.563em) {
  html {
    font-size: 50%;
  }
}

body {
  font-weight: 400;
  line-height: 1.5;
}

#app {
  position: relative;
}

@font-face {
  font-family: Cera Pro;
  src: url("CeraPro-Regular.c74ad13b.eot");
  src: url("CeraPro-Regular.c74ad13b.eot#iefix") format("embedded-opentype"), url("CeraPro-Regular.a149a710.woff") format("woff"), url("CeraPro-Regular.8a2c205d.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Cera Pro;
  src: url("CeraPro-Italic.ce607b5a.eot");
  src: url("CeraPro-Italic.ce607b5a.eot#iefix") format("embedded-opentype"), url("CeraPro-Italic.9478740a.woff") format("woff"), url("CeraPro-Italic.64ea67c8.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Cera Pro;
  src: url("CeraPro-Bold.9ffad672.eot");
  src: url("CeraPro-Bold.9ffad672.eot#iefix") format("embedded-opentype"), url("CeraPro-Bold.db00ce80.woff") format("woff"), url("CeraPro-Bold.68177408.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.virtualized-list {
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.virtualized-list::-webkit-scrollbar {
  width: 0;
  height: 0;
}
/*# sourceMappingURL=index.30a0c824.css.map */
